.audio-controls {
    display: flex;
    gap: 10px;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .icon-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }