@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Nunito:wght@400;700&display=swap');

:root {
  --primary-color: #4ECDC4; /* Turquoise */
  --secondary-color: #FFD93D; /* Bright Yellow */
  --accent-color: #FF6B6B; /* Bright Red */
  --background-color: #45B7D1; /* Sky Blue */
  --text-color: #2C3A47; /* Dark Slate */
  --light-text-color: #FFFFFF; /* White */
  --success-color: #7BED9F; /* Light Green */
  --error-color: #FF4757; /* Bright Red */
  --quiz-background: #F1F8FF; /* Very Light Blue */
  --winner-background: #FF9F43; /* Bright Orange */
  --border-radius: 20px;
  --box-shadow: 0 6px 0 rgba(0, 0, 0, 0.1), 0 8px 10px rgba(0, 0, 0, 0.1);
  --pattern-color1: rgba(255, 255, 255, 0.05);
  --pattern-color2: rgba(255, 255, 255, 0.1);
}

/* Global Styles */
body {
  font-family: 'Nunito', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  background-color: var(--background-color);
  background-image: 
    linear-gradient(45deg, var(--pattern-color1) 25%, transparent 25%, transparent 75%, var(--pattern-color1) 75%, var(--pattern-color1)),
    linear-gradient(45deg, var(--pattern-color1) 25%, transparent 25%, transparent 75%, var(--pattern-color1) 75%, var(--pattern-color1)),
    linear-gradient(-45deg, var(--pattern-color2) 25%, transparent 25%, transparent 75%, var(--pattern-color2) 75%, var(--pattern-color2)),
    linear-gradient(-45deg, var(--pattern-color2) 25%, transparent 25%, transparent 75%, var(--pattern-color2) 75%, var(--pattern-color2));
  background-size: 60px 60px, 60px 60px, 30px 30px, 30px 30px;
  background-position: 0 0, 30px 30px, 0 0, 15px 15px;
  animation: patternMove 60s linear infinite;
}

@keyframes patternMove {
    0% {
      background-position: 0 0, 30px 30px, 0 0, 15px 15px;
    }
    100% {
      background-position: 60px 60px, 90px 90px, 30px 30px, 45px 45px;
    }
  }

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  width: 95%;
}

h1, h2, h3 {
  font-family: 'Fredoka One', cursive;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 20px;
  text-shadow: 2px 2px 0 var(--text-color);
}

h1 { font-size: 3em; }
h2 { font-size: 2.5em; }
h3 { font-size: 2em; }

.input-group {
  margin-bottom: 15px;
  background-color: var(--quiz-background);
  border-radius: var(--border-radius);
  padding: 15px;
  max-width: 250px;
  margin: 0 auto 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
  font-weight: bold;
  font-size: clamp(1em, 3vw, 1.2em);
}

.input-group input {
  width: 100%;
  padding: 10px;
  font-size: clamp(0.9em, 2.5vw, 1.1em);
  border: 3px solid var(--secondary-color);
  border-radius: var(--border-radius);
  background-color: var(--light-text-color);
  color: var(--text-color);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: center;
}

button, .btn {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  padding: 12px 20px;
  font-size: clamp(1em, 3vw, 1.3em);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: var(--box-shadow);
  margin: 10px auto;
  font-weight: bold;
  text-transform: uppercase;
  width: 80%;
  max-width: 250px;
  display: block;
}

button:hover, .btn:hover {
  background-color: var(--winner-background);
  color: var(--light-text-color);
  transform: translateY(-3px);
}

.error {
  color: var(--light-text-color);
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  font-size: 1.2em;
  background-color: var(--error-color);
  padding: 10px;
  border-radius: var(--border-radius);
}

/* Create Room Screen */


.caption {
  font-size: 1.5rem;
  color: var(--secondary-color);
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.game-setup {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: var(--box-shadow);
}

.game-rules {
  margin-bottom: 20px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius);
}

.game-rules h3 {
  color: var(--accent-color);
  margin-bottom: 10px;
  text-align: center;
}

.game-rules ul {
  list-style-type: none;
  padding-left: 0;
}

.game-rules li {
  color: var(--text-color);
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}


.input-container {
  margin-bottom: 20px;
}


.game-setup {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 30px;
  margin: 0 auto 20px;
  border: 4px solid var(--secondary-color);
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.game-setup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.title {
  margin-bottom: 0.5rem;  /* Reduce bottom margin of title */
}

.info-box,
.game-setup {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: var(--box-shadow);
}
.info-box .caption {
  color: var(--secondary-color);
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center;
}

.game-rules h3 {
  color: var(--accent-color);
  margin-bottom: 10px;
}

.game-rules ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.game-rules {
  margin-bottom: 20px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius);
}

.game-rules li {
  color: var(--text-color);
  margin-bottom: 8px;
  text-align: center;
}


.input-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  max-width: 800px;
}

.uppercase-input {
  text-transform: uppercase;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-group label {
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
}

.input-group button {
  width: 100%;
}

.leaderboard {
  width: 100%;
  max-width: 800px;
}

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard th, .leaderboard td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
  }
}

.game-setup h2 {
  color: var(--secondary-color);
  font-size: clamp(1.8em, 5vw, 2.5em);
  margin-bottom: 20px;
  text-shadow: 2px 2px 0 var(--text-color);
}

.btn.create {
  margin-bottom: 20px;
}

.btn.join {
  background-color: var(--accent-color);
  color: var(--light-text-color);
}

/* Waiting Room Screen */
.waiting-room {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  margin-bottom: 20px;
  border: 4px solid var(--secondary-color);
}

.quest-code {
  background-color: var(--secondary-color);
  padding: 15px;
  border-radius: var(--border-radius);
  margin-top: 20px;
  border: 3px dashed var(--accent-color);
}

.code-display {
  font-size: 2.5em;
  color: var(--text-color);
  font-weight: bold;
  letter-spacing: 5px;
}

.share-message, .waiting-message {
  color: var(--text-color);
  font-style: italic;
  font-size: 1.2em;
}

.player-count {
  font-size: 1.4em;
  color: var(--text-color);
  margin-top: 15px;
  font-weight: bold;
}

/* Game Screen */
.game {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 20px;
  margin-bottom: 20px;
  border: 4px solid var(--secondary-color);
  text-align: center;
  height: calc(100vh - 250px); /* Adjust based on your layout */
  display: flex;
  flex-direction: column;
}

.game-info {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 15px;
  border-radius: var(--border-radius);
  margin-bottom: 15px;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.game-info {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 20px;
}

.game-info-left,
.game-info-center,
.game-info-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Added this line */
}

.game-info-left {
  align-items: flex-start;
}

.game-info-right {
  align-items: flex-end;
}

.current-player {
  margin-top: 10px;
  font-weight: bold;
}

/* Ensure the Scoreboard is centered within its container */
.game-info-center .Scoreboard {
  align-self: center;
}

.game-info p {
  margin: 0;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
}

.scoreboard {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: var(--quiz-background);
  border-radius: var(--border-radius);
  padding: 10px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.score-item {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: 5px 10px;
  border-radius: var(--border-radius);
  font-size: 0.9em;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.score-item:hover {
  transform: translateY(-2px);
}

.player-name {
  margin-right: 5px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
}

.player-score {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  padding: 2px 8px;
  border-radius: 12px;
  font-weight: bold;
  min-width: 24px;
  text-align: center;
}

.player-panels {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px;
}

.player-panel {
  flex: 1 1 300px;
  max-width: 400px;
  background-color: var(--quiz-background);
  padding: 15px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  border: 3px solid var(--secondary-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
}

.player-panel h3 {
  color: var(--accent-color);
  font-size: 1.5em;
  margin-bottom: 5px;
}

.player-panel p {
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 5px;
}

.question-status {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 2px;
  display: inline-block;
}

.status-circle.correct {
  background-color: var(--success-color);
}

.status-circle.incorrect {
  background-color: var(--error-color);
}

.status-circle.unanswered {
  background-color: var(--light-text-color);
  border: 2px solid var(--text-color);
}

.current-question {
  background-color: var(--secondary-color);
  padding: 10px;
  border-radius: var(--border-radius);
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.current-question h4 {
  color: var(--text-color);
  font-size: 1.2em;
  margin-bottom: 5px;
}

.current-question p {
  font-size: 1.4em;
  font-weight: bold;
  color: var(--text-color);
}

.player-panel form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-panel input[type="number"] {
  width: 100%;
  max-width: 150px;
  padding: 8px;
  font-size: 1em;
  border: 2px solid var(--secondary-color);
  border-radius: var(--border-radius);
  margin-bottom: 5px;
  text-align: center;
}

.player-panel button {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  border: none;
  padding: 8px 15px;
  font-size: 1em;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.3s ease;
}

.player-panel button:hover {
  background-color: var(--winner-background);
  transform: translateY(-2px);
}

/* Game Over Screen */
.game-over {
  background-color: var(--primary-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 30px;
  margin-bottom: 20px;
  border: 4px solid var(--secondary-color);
  text-align: center;
}

.game-over .winner {
  background-color: var(--winner-background);
  color: var(--light-text-color);
  font-size: 2em;
  font-weight: bold;
  padding: 15px;
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  box-shadow: var(--box-shadow);
}

.game-over .winner-label {
  display: block;
  font-size: 0.7em;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.final-scores {
  background-color: var(--quiz-background);
  border-radius: var(--border-radius);
  padding: 20px;
  margin-top: 20px;
}

.score-item {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 10px;
  margin: 10px 0;
  border-radius: var(--border-radius);
  font-size: 1.3em;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.score-item .player-name {
  flex-grow: 1;
  text-align: left;
}

.score-item .player-score {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: 5px 10px;
  border-radius: 10px;
  min-width: 50px;
  text-align: center;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .result-popup {
    background-color: var(--quiz-background);
    border: 2px solid var(--accent-color);
    border-radius: var(--border-radius);
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .result-popup h3 {
    margin-top: 0;
    color: var(--accent-color);
  }

  .result-popup h2 {
    margin-top: 0;
    color: var(--success-color);
  }
  
  .result-popup .question {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .result-popup .answer {
    margin-bottom: 5px;
  }
  
  .result-popup .result {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
  }
  
  .result-popup .result.correct {
    background-color: #4CAF50;
    color: white;
  }
  
  .result-popup .result.incorrect {
    background-color: #F44336;
    color: white;
  }
  
  .result-popup .highlight {
    font-weight: bold;
    color: var(--accent-color);
  }

  .timer-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: var(--accent-color);
    transform-origin: left;
    transition: transform 0.033s linear;
  }
  
  .result-popup.correct .timer-bar {
    background-color: #4CAF50;
  }
  
  .result-popup.incorrect .timer-bar {
    background-color: #F44336;
  }

  /* Game Over Screen Styles */
.game-over {
    background-color: var(--primary-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    padding: 30px;
    margin-bottom: 20px;
    border: 4px solid var(--secondary-color);
    text-align: center;
  }
  
  .game-over-title {
    color: var(--accent-color);
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .score-summary {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .highlight {
    color: var(--accent-color);
    font-weight: bold;
  }
  
  .streak-message {
    background-color: var(--secondary-color);
    color: var(--text-color);
    padding: 15px;
    border-radius: var(--border-radius);
    margin-bottom: 20px;
    font-size: 1.1em;
  }
  
  .streak-message.improved {
    background-color: var(--success-color);
    color: var(--light-text-color);
  }
  
  .streak-message.ended {
    background-color: var(--error-color);
    color: var(--light-text-color);
  }
  
  .leaderboard-container {
    background-color: var(--quiz-background);
    border-radius: var(--border-radius);
    padding: 20px;
    margin-top: 20px;
  }
  
  .leaderboard-container h3 {
    color: var(--accent-color);
    margin-bottom: 10px;
  }
  
  .leaderboard {
    width: 100%;
    border-collapse: collapse;
  }
  
  .leaderboard th, .leaderboard td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid var(--secondary-color);
  }
  
  .leaderboard th {
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-weight: bold;
  }
  
  .leaderboard .current-pair {
    background-color: var(--winner-background);
    color: var(--light-text-color);
    font-weight: bold;
  }
  
  .current-ranking {
    font-size: 1.1em;
    margin: 20px 0;
  }
  
  .play-again-btn {
    background-color: var(--accent-color);
    color: var(--light-text-color);
    border: none;
    padding: 10px 20px;
    font-size: 1.1em;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .play-again-btn:hover {
    background-color: var(--winner-background);
    transform: translateY(-2px);
  }
  
  @keyframes timer-animation {
    from {
      width: 100%;
    }
    to {
      width: 0%;
    }
  }

  .result-popup.correct .timer-bar {
  background-color: #4CAF50;
}

.result-popup.incorrect .timer-bar {
  background-color: #F44336;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 { font-size: 2.5em; }
  h2 { font-size: 2em; }
  h3 { font-size: 1.7em; }
  .code-display { font-size: 2em; }
  .question { font-size: 1.5em; }
  .player-panel { flex: 1 1 100%; }
  .game-container {
    height: calc(100vh - 80px);
  }
  .player-panel {
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .App { padding: 10px; }
  h1 { font-size: 2em; }
  h2 { font-size: 1.7em; }
  h3 { font-size: 1.5em; }
  .game-setup, .waiting-room, .game-container, .game-over { padding: 20px; }
  .input-group { padding: 10px; }
  .input-group input { padding: 8px; }
  button, .btn { 
    padding: 10px 15px;
    width: 90%;
  }
  .code-display { font-size: 1.7em; }
  .question { font-size: 1.3em; }
  .game-info { font-size: 1em; padding: 10px; }
  .player-panel { flex-basis: 100%; }
  .player-panel h3 { font-size: 1.3em; }
  .current-question p { font-size: 1.2em; }
  .player-panel input[type="number"] { font-size: 1em; }
  .player-panel button { font-size: 1em; padding: 10px 20px; }
  .game {
    height: calc(100vh - 60px);
    padding: 10px;
  }
  .score-item {
    font-size: 0.8em;
    padding: 3px 8px;
  }

  .player-name {
    max-width: 80px;
  }
}